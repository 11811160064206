import React from 'react';
import './Frontend.scss';
import { Skill } from '../Skill';

function Frontend() {
  const path = 'frontend';
  const title = 'Frontend-Entwicklung';
  const badges = ['html5', 'angular', 'react', 'typescript', 'sass', 'webpack', 'pnpm', 'jsf', 'jest', 'selenium', 'karma', 'playwright', 'cloudflare'];
  const text = `
    Für meinen Geschmack sollten Frontends strikt von Backends und der Businesslogik getrennt sein.
    Bei der Frontendentwicklung arbeite ich konsequent modul- oder komponentenorientert.
    Ich achte dabei auf Wiederverwendbarkeit und Konfigurierbarkeit, um die Weiterentwicklung und die Zusammenarbeit
    im Team möglichst effizient zu gestalten.
    <br>
    Ich habe mit zahlreichen Javascript Frameworks gearbeitet, wobei Schwerpunkt bei Angular und React liegt.
    <br><br>
    Zur Featureentwicklung gehört das Schreiben von sinnvollen Componenten und Integrationstests.
    <br>
    Dazu bediene 
    <br><br>
    Oberflächentests sind sinnvoll, um ein gewünschtes Verhalten zu verifizieren. Ich bin allerdings kein Freund davon
    Tests der Tests wegen zu schreiben. Besonders in agilen Projekten, die teils sehr oberflächenlastig sind, entstehen
    schnell neue Anforderungen an die Benutzerführung. Häufig ist es sinnvoll, Oberflächentests etwas nachzulagern.
    <br><br>
    Ich nutze gerne CSS Preprocessoren wie SASS. Von CSS Frameworks rate ich allerdings in vielen Fällen ab.
    Dazu lasse ich mich gerne in einem persönlichen Gespräch aus :)
  `;

  return (
    <Skill path={path} title={title} badges={badges} text={text} />
  );
}

export default Frontend;

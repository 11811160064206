import React from 'react';
import './Cloud.scss';
import { Skill } from '../Skill';

function Cloud() {
  const path = 'cloud';
  const title = 'Cloud services (aws)';
  const badges = ['terraform', 'ecs', 'ec2', 'ecr', 'lambda', 'dynamoDB', 'secrets manager', 'cloudwatch', 'iam', 'eventBridge'];
  const text = `
    Infrastruktur als Code und hohe Verfügbarkeiten sind nur ein Teil der Vorteile von Cloud Diensten.
    Mit wenigen Zeilen lässt sich eine vollständige hochskalierbare Architektur nachvollziehbar und revisionssicher aufbauen.
    <br>
    Ich habe Erfahrung in vielen Bereichen der AWS cloud und der Konfiguration via Terraform.<br>
    <br>
    Um nur ein paar Beispiele zu nennen:<br>
    <ul>    
      <li>Bereitstellen von Services im ECS cluster</li>
      <li>Entwicklung von Lambda Funktionen</li>
      <li>Aufbau und Verarbeitung einer event queue</li>
      <li>Automatische Rotation von Secrets</li>
    </ul>
  `;

  return (
    <Skill path={path} title={title} badges={badges} text={text} />
  );
}

export default Cloud;

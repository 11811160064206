import React, { useEffect, useState } from 'react';
import './Header.scss';
import Logo from '../assets/logo.png';
import Frontend from '../assets/skills/004-reaktionsschnell.svg';
import Backend from '../assets/skills/008-server.svg';
import Cloud from '../assets/skills/aws.svg';
import Microservices from '../assets/skills/microservices.svg';
import Devops from '../assets/skills/devops.svg';
import Methodik from '../assets/skills/006-agil.svg';
import Menu from '../assets/menu.png';
import { Link, useLocation } from 'react-router-dom';

function Header(props: any) {
  const [menuOpen, setMenu] = useState(false);
  const toggleMenu = () => setMenu(!menuOpen);
  const closeMenu = () => setMenu(false);

  const location = useLocation();
  useEffect(() => closeMenu(), [location]);

  return (
    <header className="header">
      <Link to="/">
        <img src={Logo} alt="Julian Reichwein" className="logo" />
      </Link>
      <div className="mobile-menu-toggle" onClick={toggleMenu}><img src={Menu} alt="Menu"/></div>
      <div className={"skills" + (menuOpen ? ' open' : '')}>
      <Link to="/frontend">
          <div className="item">
            <img src={Frontend} alt="Frontend" />
            <div>Frontend</div>
          </div>
        </Link>
        <Link to="/backend">
          <div className="item">
            <img src={Backend} alt="Backend" />
            <div>Backend</div>
          </div>
        </Link>
        <Link to="/cloud">
          <div className="item">
            <img src={Cloud} alt="Cloud services" />
            <div>Cloud (AWS)</div>
          </div>
        </Link>
        <Link to="/microservices">
          <div className="item">
            <img src={Microservices} alt="Microservices" />
            <div>Microservices</div>
          </div>
        </Link>
        <Link to="/devops">
          <div className="item">
            <img src={Devops} alt="DevOps" />
            <div>Devops</div>
          </div>
        </Link>
        <Link to="/methodik">
          <div className="item">
            <img src={Methodik} alt="Methodik" />
            <div>Methodik</div>
          </div>
        </Link>
      </div>
    </header>
  );
}

export default Header;

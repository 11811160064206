import React from 'react';
import './legal.scss';

function Impressum() {
  return (
    <div className="content impressum">
      <div className="content-main">
        <h3>Impressum</h3>
        <div className="text">
          <h4>Seitenbetreiber / Verantwortlicher</h4>
          <div>
            Julian Reichwein - Fullstack Development Services<br />
            Lattengasse 22<br/>
            65604 Elz<br/>
            USt-Id: DE 52864410370<br/>
            <br/>
            E-Mail: hello@julianreichwein.com<br/>
            Tel: 0174 790 3352
          </div>
          <br/>
          <h5>Grafiken und Icons</h5>
          <div className="links">
            Von:&nbsp;
            <a href="https://www.flaticon.com/de/autoren/freepik" title="Freepik">Freepik</a>,&nbsp;
            <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com</a>,&nbsp;
            <a href="https://www.flaticon.com/de/autoren/darius-dan" title="Darius Dan">Darius Dan</a>,&nbsp; 
            <a href="https://www.flaticon.com/de/autoren/eucalyp" title="Eucalyp">Eucalyp</a>,&nbsp;
            <a href="https://www.flaticon.com/de/autoren/becris" title="Becris">Becris</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Impressum;

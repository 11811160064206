import React from 'react';
import './Content.scss';
import Frontend from './frontend/Frontend';
import Backend from './backend/Backend';
import Microservices from './microservices/Microservices';
import Devops from './devops/Devops';
import Methodik from './methodik/Methodik';
import Start from './start/Start';
import History from './history/History';
import Impressum from '../legal/Impressum';
import Datenschutz from '../legal/Datenschutz';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Route, Routes, useLocation } from "react-router-dom";
import Cloud from "./cloud/Cloud";

function Content() {
  const location = useLocation();
  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route path="/frontend" Component={Frontend}/>
          <Route path="/backend" Component={Backend}/>
          <Route path="/cloud" Component={Cloud}/>
          <Route path="/microservices" Component={Microservices}/>
          <Route path="/devops" Component={Devops}/>
          <Route path="/methodik" Component={Methodik}/>
          <Route path="/history" Component={History}/>
          <Route path="/impressum" Component={Impressum}/>
          <Route path="/datenschutz" Component={Datenschutz}/>
          <Route path="/" Component={Start}/>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default Content;

import React from 'react';
import './History.scss';

function History() {
  const projects: Project[] = [
    {title: 'MyPorsche Aftersales', duration: '08/2021 – Heute', customer: 'Porsche AG'},
    {title: 'Key Management Solution', duration: '04/2021 – 08/2021', customer: 'Escrypt GmbH'},
    {title: 'E-Accounting', duration: '04/2019 - 03/2021', customer: 'Deutscher Genossenschafts-Verlag'},
    {title: 'Ingen', duration: '08/2018 – 03/2019', customer: 'DZ Bank'},
    {title: 'InnovationLab', duration: '05/2018 – 08/2018', customer: 'DZ Bank'},
    {title: 'Asset Control', duration: '08/2017 – 05/2018', customer: 'Union Investment'},
    {title: 'Visual Vest', duration: '06/2017 – 08/2017', customer: 'Visual Vest GmbH'},
    {title: 'Service Tool', duration: '11/2016 – 04/2017', customer: 'Protec IT Solutions'}
  ];
  return (
    <div className="content history"> 
        <div className="content-main">
          <h2>Projekthistorie</h2>
          <div className="small">
            <span>Detaillierte Beschreibungen zu den Projekten finden Sie in meinem </span>
            <a href="mailto: hello@julianreichwein.com">Profil</a>.
          </div>
          <div className="text projects">
            { projects.map(project => (
              <div className="project">
                <div className="title">{project.title}</div>
                <div className="info">
                  <div className="duration">{project.duration}</div>
                  <div className="customer">{project.customer}</div>
                </div>
              </div>
            ))}
        </div>
        </div>
      </div>
  );
}
export default History;

type Project = {
  title: string;
  duration: string;
  customer: string;
}

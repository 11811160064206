import React from 'react';
import './Backend.scss';
import { Skill } from '../Skill';

function Backend() {
  const path = 'backend';
  const title = 'Backend-Entwicklung';
  const badges = ['java', 'kotlin', 'jee', 'spring', 'spring boot', 'maven', 'gradle', 'hibernate', 'jpa', 'junit', '*SQL', 'dynamoDb', 'mongodb' , 'liquibase', 'flyway'];
  const text = `
    Backend-Code sollte serviceorientiert sein, wenige Abhängigkeiten haben und in fachliche Domänen unterteilt sein.
    <br>
    Dabei bevorzuge eine Schichten-Architektur, über die Endpunkte von Logik und der Datenmodellierung getrennt werden.
    <br><br>
    Bei der Backend-Entwicklung lege ich Wert darauf, dass Features testgetrieben geschrieben werden.
    Da reine Backends den Großteil der Business-Logik abbilden, sorgen sinnvolle Tests neben der Verifizierung des Codes
    auch zur Beschreibung der Logik für andere Entwickler.
  `;

  return (
    <Skill path={path} title={title} badges={badges} text={text} />
  );
}

export default Backend;

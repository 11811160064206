import React from 'react';
import './App.scss';
import Profile from './profile/Profile';
import Content from './content/Content';
import Header from './header/Header';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <div className="app">
      <Router>
        <Header />
        <Content />
        <Profile />
      </Router>
    </div>
  );
}

export default App;

import React from 'react';
import './Microservices.scss';
import { Skill } from '../Skill';

function Microservices() {
  const path = 'microservices';
  const title = 'Microservices und Tools';
  const badges = ['webservices', 'rest', 'consul', 'rancher', 'openapi', 'keycloak', 'auth0', 'oauth2', 'kafka'];
  const text = `
    "Microservices" ist das Buzzword für moderne Software-Entwicklung.
    <br>
    Kleine, übersichtliche Services, die ihr eigenes Deployment haben und per Instanz ohne wahrnehmbare
    Downzeiten geupdated oder dupliziert werden können, klingt aus Entwicklersicht einfach genial.
    <br><br>
    Eine "echte" Microservice Landschaft ist allerdings nicht ausnahmslos zu empfehlen. Die Vorteile, die sich
    in der Weiterentwicklung und dem Betrieb ergeben, müssen einem hohen Initialaufwand gegenüber gestellt werden.
    <br><br>
    Ich berate und entwickele in Themen rund um Microservices und zugehörigen Tools.
    <br>
    Als ergänzende Services in modernen Web-Architekturen, kann ich zudem auf Erfahrungen mit OAuth2 Konzepten in
    der Konfiguration mit Keycloak, als auch mit Messaging Providern in Form von Kafka, greifen.
  `;

  return (
    <Skill path={path} title={title} badges={badges} text={text} />
  );
}

export default Microservices;

import React from 'react';
import './Devops.scss';
import { Skill } from '../Skill';

function Devops() {
  const path = 'devops';
  const title = 'DevOps - Engineering';
  const badges = ['docker', 'rancher', 'linux', 'wildfly', 'tomcat', 'traefik', 'nginx', 'git', 'jenkins', 'github actions', 'sonarqube', 'splunk', 'new relic'];
  const text = `
    Konfiguration und Automatisierung ist der Schlüssel zu einer hoch effizienten Softwareentwicklung im Team.
    Entwickler sollten sich nicht mit stetig wiederholenden Themen beschäftigen, die sich einfach automatisieren lassen.
    <br><br>
    Neben den Schreiben von Deployment-Pipelines, sehe ich in DevOps-Tätigkeiten jede Maßnahme zur Verbesserung
    der Zusammenarbeit von Entwicklungsteams.
    Dazu gehören auch, bereits vor der ersten Zeile Code, einheitlich konfigurierte Entwicklungssysteme, durch die Entwickler
    schnell unter "realitätsnahen" Bedingungen entwickeln können.
    <br><br>
    Ich habe CICD Pipelines mit Jenkins, Concourse und Github Actions aufgebaut und die mit der Pipeline verbundenen
    Systeme konfiguriert.
    Darunter Linux als Server-Basis, Wildfly/Tomcat als Application-, Apache2/nginx als Webserver, sowie Rancher zur Orchestrierung,
    als auch Sonarqube zur statischen Codeanalyse.
  `;

  return (
    <Skill path={path} title={title} badges={badges} text={text} />
  );
}

export default Devops;

import React from 'react';
import './Start.scss';
import { Link } from 'react-router-dom';
import History from '../../assets/history.svg';
import Profile from '../../assets/profile.svg';

function Start() {
  return (
    <div className="content startpage">
      <div className="content-main">
        <h2>Hej, ich bin Julian Reichwein,</h2>
        <div className="summary">
          IT-Berater in Fullstack Webentwicklung und DevOps Engineering.
          Neben der technischen Umsetzung, berate ich zu fachlichen Herausforderungen mit technischen Lösungen
          sowie zu Prozessen in der agilen Softwareentwicklung.
          Meine Expertise liegt in modularer Javascript Frontend- und Kotlin/Java basierter Backendentwicklung, sowie
          Microservicearchitekturen und vielen AWS Cloud Diensten.
          Ich habe langjährige Projekterfahrung in agilen Entwicklungsteams und der Kollaboration mit der jeweiligen
          Business-Welt, Software Architekten, Schnittstellenprovidern, sowie UX Designern.
        </div>
        <div className="further-links">
          <Link to="/history">
            <div className="icon-with-text">
              <img className="icon" src={History} alt="Projekthistorie" />
              <div>Projekthistorie</div>
            </div>
          </Link>
          <a href="mailto: hello@julianreichwein.com">
            <div className="icon-with-text">
              <img className="icon" src={Profile} alt="Profil" />
              <div>Profil anfragen</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Start;

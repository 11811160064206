import React from 'react';
import './Methodik.scss';
import { Skill } from '../Skill';

function Methodik() {
  const path = 'methodik';
  const title = 'Agile Softwareentwicklung';
  const badges = ['agile', 'scrum', 'safe', 'jira', 'confluence', 'slack', 'teams', 'figma', 'miro', 'documentation'];
  const text = `
    Softwareprojekte nach agilen Vorgehensmodellen sorgen durch kleinteilige Zerlegung komplexer Themen dafür, 
    dass Features für alle Projektbeteiliten greifbarer, und Projektabschnitte planbarer werden.
    <br><br>
    Oftmals ist es eine Kunst das technische ToDo aus der fachlichen Anforderung abzuleiten.
    <br>
    Ich kann mich gut in fachliche Themen einarbeiten, um die richtigen Fragen aus technischer Sicht
    stellen. Das kann unter Umständen etwas dauern, verhindert aber Anpassungen über Anpassungen...
    <br><br>
    Ich habe in so gut wie jedem Projekt in agilen Entwicklungsteams mit Scrum (und Safe) gearbeitet.
    Zudem bin ich zertifizierter ScrumMaster und berate zu prozessualen Themen in der agiler Softwareentwicklung.
  `;

  return (
    <Skill path={path} title={title} badges={badges} text={text} />
  );
}

export default Methodik;

import React from 'react';

export type Props = {
  path: string;
  title: string;
  badges?: string[];
  text: string;
}
export class Skill extends React.Component<Props> {
  render() { 
    const path = ' ' + this.props.path;
    const title = this.props.title;
    const badges = this.props.badges || [];
    const text = this.props.text;
    return (
      <div className={'content' + path}> 
        <div className="content-main">
          <h2>{title}</h2>
          {badges && badges.length > 0 && 
            <div className="badges">
              {badges.map(badge => (
                <span className="badge">{badge}</span>
              ))}
            </div>
          }
          <div className="text" dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import './Profile.scss';
import Bild from '../assets/bild-nobg.png';
import { Link, useLocation } from "react-router-dom";
import Mail from '../assets/email.png';
import Phone from '../assets/telephone.png';
import Availability from '../assets/available.png';
import Legal from '../assets/legal.png';

function Profile(props: any) {
  const location = useLocation();
  const startpage = location.pathname === '/' ? ' startpage' : '';
  const available = true;
  const statusText = available ? 'Verfügbar' : 'Nicht verfügbar';

  return (
    <div className={'profile' + startpage}>
      <img src={Bild} alt="Julian Reichwein" className="image"/>
      <div className="infobox">
        <h1 className="head noborder">Julian Reichwein - Fullstack Dev</h1>
        <div>IT Beratung - Fullstack Webentwicklung - DevOps Engineering</div>
        <hr className="separator"/>
        <div className="contact">
          <div><img src={Mail} alt="Mail"/></div>
          <div><a href="mailto: hello@julianreichwein.com">hello@julianreichwein.com</a></div>
          <div><img src={Phone} alt="Telefon"/></div>
          <div><a href="tel: +491747903352">0174 790 3352</a></div>
          <div><img src={Availability} alt="Verfügbarkeit"/></div>
          <div className="statusCell">
            <div className={'availability ' + available}></div>
            {statusText}
          </div>
          <div className="legal"><img src={Legal} alt="Legal"/></div>
          <div className="legal">
            <Link to="/impressum">Impressum</Link>
            <span>  |  </span>
            <Link to="/datenschutz">Datenschutz</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
